import React from 'react';

interface ChatMessageProps {
  chat: {
    world: string;
    time: string;
    x: string;
    y: string;
    z: string;
    message: string;
    username: string;
  };
}

const ChatMessage: React.FC<ChatMessageProps> = ({ chat }) => {
  return (
    <div className="chat-message">
      <div className="chat-header">
        <span className="username">{chat.username}</span>
        <span> </span>
        <span className="time">{chat.time}</span>
      </div>
      <p className="message">{chat.message}</p>
      <div className={`coords ${chat.world === '主世界' ? 'main' : chat.world === '地狱' ? 'nether' : 'end'}`}>
        {chat.world}：({chat.x}, {chat.y}, {chat.z})
    </div>
    </div>
  );
};

export default ChatMessage;