import React from 'react';
import ChatMessage from './ChatMessage';

interface ChatRecordProps {
  chatData: Array<{
    world: string;
    time: string;
    x: string;
    y: string;
    z: string;
    message: string;
    username: string;
  }>;
}

const ChatRecord: React.FC<ChatRecordProps> = ({ chatData }) => {
  return (
    <div className="chat-record">
      {chatData.map((chat, index) => (
        <ChatMessage key={index} chat={chat} />
      ))}
    </div>
  );
};

export default ChatRecord;