import React, { useState, useEffect } from 'react';
import ChatRecord from './ChatRecord';
import './App.css';

const App: React.FC = () => {
  const [chatData, setChatData] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10; // 每页的消息数

  useEffect(() => {
    fetchChatData(page, limit);
  }, [page]);

  const fetchChatData = async (page: number, limit: number) => {
    const response = await fetch(`https://api.scumc.wjlo.cc/getchat?limit=${limit}&page=${page}`);
    const data = await response.json();
    setChatData(data);
  };

  const handleNextPage = () => setPage(page + 1);
  const handlePreviousPage = () => setPage(page > 1 ? page - 1 : 1);

  return (
    <div className="app">
      <h1>聊天记录</h1>
      <br></br>
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={page === 1}>上一页</button>
        <span> 第 {page} 页 </span>
        <button onClick={handleNextPage}>下一页</button>
      </div>
      <br></br>
      <ChatRecord chatData={chatData} />
      <br></br>
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={page === 1}>上一页</button>
        <span> 第 {page} 页 </span>
        <button onClick={handleNextPage}>下一页</button>
      </div>
    </div>
  );
};

export default App;